
















































































































































































.seminar-day {
  background: #F2F5FE!important;
  box-shadow: 0 0 0 2px #bbb inset!important;
  color: #000000!important;
  &.active {
    box-shadow: 0 0 0 2px #6177ff inset!important;
  }
}
.no-seminar {
  margin: 10% 0 50%;
  text-align: center;
  font-size: 20px;
}
