















































































































































































































































































































































.content {
  position: relative;
}
.spinner-text {
  text-align: center;
  position: absolute;
  top: 40%;
  width: 100%;
  left: 0;
}
.fancybox__backdrop {
  background: rgba(24, 24, 27, 0.7);
}
html.with-fancybox body.hide-scrollbar {
  margin-right: auto;
}
